import React from 'react'
import Container from 'components/common/Container'
import { Details, TermsOfServiceWrapper, Wrapper } from './styles'

export default () => {
  return (
    <Wrapper id="terms-of-service">
      <TermsOfServiceWrapper as={Container}>
        <Details>
          <h1>TERMS OF SERVICE</h1>
          <p>
            These terms and conditions outline the rules and regulations for the use of CloudBuilder website.
          </p>
          <p>
            By accessing this website we assume you accept these terms and conditions in full. Do not continue to use
            CloudBuilder BVBA's website if you do not accept all of the terms and conditions stated on this page.
          </p>
          <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice
            and any or all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website and
            accepting the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to
            our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or
            ourselves. All terms refer to the offer, acceptance and
            consideration of payment necessary to undertake the process of our assistance to the Client in the most
            appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express
            purpose of meeting the Client’s needs in respect of provision of the Company’s stated services/products, in
            accordance with and subject to, prevailing law of Belgium. Any use of the above terminology or other words
            in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as
            referring to same.
          </p>
          <h2>COOKIES</h2>
          <p>We employ the use of cookies. By using CloudBuilder BVBA's website you consent to the use of cookies in
            accordance with CloudBuilder BVBA’s privacy policy. Most of the modern day interactive websites use cookies
            to enable us to retrieve user details for each visit. Cookies are used in some areas of our site to enable
            the functionality of this area and ease of use for those people visiting. Some of our affiliate /
            advertising partners may also use cookies.
          </p>
          <h2>LICENSE</h2>
          <p>Unless otherwise stated, CloudBuilder BVBA and/or it’s licensors own the intellectual property rights for
            all material on CloudBuilder BVBA. All intellectual property rights are reserved. You may view and/or print
            pages from https://www.cloudbuilder.be for your own personal use subject to restrictions set in these terms
            and conditions. You must not:
          </p>
          <ul>
            <li>Republish material from https://www.cloudbuilder.be</li>
            <li>Sell, rent or sub-license material from https://www.cloudbuilder.be</li>
            <li>Reproduce, duplicate or copy material from https://www.cloudbuilder.be</li>
            <li>
              Redistribute content from CloudBuilder BVBA (unless content is specifically made for redistribution)
            </li>
          </ul>
          <h2>HYPERLINKING TO OUR CONTENT</h2>
          <p>The following organisations may link to our website without prior written approval:</p>
          <ul>
            <li>Government agencies</li>
            <li>Search engines</li>
            <li>News organisations</li>
            <li>Online directory distributors when they list us in the directory may link to our website in the same
              manner as they hyperlink to the websites of other listed businesses
            </li>
            <li>Systemwide Accredited Businesses except soliciting non-profit organisations, charity shopping malls, and
              charity fundraising groups which may not hyperlink to our website
            </li>
          </ul>
          <p>These organisations may link to our homepage, to publications or to other website information so long as
            the link:</p>
          <ul>
            <li>is not in any way misleading</li>
            <li>
              does not falsely imply sponsorship, endorsement or approval of the
              linking party and its products or services fits within the context of the linking party's site
            </li>
          </ul>
          <p>We may consider and approve in our sole discretion other link requests from the following types of
            organisations:</p>
          <ul>
            <li>
              commonly-known consumer and/or business information sources such as Chambers of Commerce, American
              Automobile Association, AARP and Consumers Union
            </li>
            <li>
              dot.com community sites associations or other groups representing charities, including charity giving
              sites, online directory distributors
            </li>
            <li>internet portals</li>
            <li>accounting, law and consulting firms whose primary clients are businesses</li>
            <li>educational institutions and trade associations</li>
          </ul>
          <p>We will approve link requests from these organisations if we determine that: </p>
          <ul>
            <li>
              the link would not reflect unfavorably on us or our accredited businesses (for example, trade
              associations or other organisations representing inherently suspect types of business, such as
              work-at-home opportunities, shall not be allowed to link)
            </li>
            <li>the organisation does not have an unsatisfactory record with us</li>
            <li>the benefit to us from the visibility associated with the hyperlink outweighs the absence of</li>
            <li>
              where the link is in the context of general resource information or is otherwise consistent with
              editorial content in a newsletter or similar product furthering the mission of the organisation
            </li>
          </ul>
          <p>These organisations may link to our home page, to publications or to other website information so long as
            the link:</p>
          <ul>
            <li>is not in any way misleading</li>
            <li>
              does not falsely imply sponsorship, endorsement or approval of the linking party and it products or
              services
            </li>
            <li>fits within the context of the linking party's site</li>
          </ul>
          <p>
            If you are among the organisations listed in paragraph 2 above and are interested in linking to our website,
            you must notify us by sending an e-mail to info@cloudbuilder.be. Please include your name, your organisation
            name, contact information (such as a phone number and/or e-mail address) as well as the URL of your site, a
            list of any URLs from which you intend to link to our website, and a list of the URL(s) on our site to
            which you would like to link. Allow 2-3 weeks for a response.
          </p>
          <p>Approved organisations may hyperlink to our website as follows:</p>
          <ul>
            <li>By use of our corporate name</li>
            <li>By use of the uniform resource locator (Web address) being linked to</li>
            <li>
              By use of any other description of our website or material being linked to that makes sense within the
              context and format of content on the linking party's site
            </li>
          </ul>
          <p>No use of CloudBuilder BVBA’s logo or other artwork will be allowed for linking absent a trademark license
            agreement.</p>
          <h2>IFRAMES</h2>
          <p>
            Without prior approval and express written permission, you may not create frames around our Web pages or use
            other techniques that alter in any way the visual presentation or appearance of our website.
          </p>
          <h2>RESERVATION OF RIGHTS</h2>
          <p>
            We reserve the right at any time and in its sole discretion to request that you remove all links or any
            particular link to our website. You agree to immediately remove all links to our website upon such
            request. We also reserve the right to amend these terms and conditions and its linking policy at any time.
            By continuing to link to our website, you agree to be bound to and abide by these linking terms and
            conditions.
          </p>
          <h2>REMOVAL OF LINKS FROM OUR website</h2>
          <p>
            If you find any link on our website or any linked website objectionable for any reason, you may contact us
            about this. We will consider requests to remove links but will have no obligation to do so or to respond
            directly to you. Whilst we endeavour to ensure that the information on this website is correct, we do not
            warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or
            that the material on the website is kept up to date.
          </p>
          <h2>CONTENT LIABILITY</h2>
          <p>
            We shall have no responsibility or liability for any content appearing on your website. You agree to
            indemnify and defend us against all claims arising out of or based upon your website. No link(s) may appear
            on any page on your website or within any context containing content or materials that may be interpreted
            as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or
            other violation of, any third party rights.
          </p>
          <h2>DISCLAIMER</h2>
          <p>
            To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions
            relating to our website and the use of this website (including, without limitation, any warranties implied
            by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill).
            Nothing in this disclaimer will:
          </p>
          <ul>
            <li>limit or exclude our or your liability for death or personal injury resulting from negligence</li>
            <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation</li>
            <li>limit any of our or your liabilities in any way that is not permitted under applicable law</li>
            <li>exclude any of our or your liabilities that may not be excluded under applicable law</li>
          </ul>
          <p>The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: </p>
          <ul>
            <li>are subject to the preceding paragraph</li>
            <li>
              govern all liabilities arising under the disclaimer or in relation to the subject matter of this
              disclaimer, including liabilities arising in contract, in tort (including negligence) and for breach of
              statutory duty
            </li>
          </ul>
          <p>
            To the extent that the website and the information and services on the website are provided free of charge,
            we will not be liable for any loss or damage of any nature.
          </p>
          <h2>CONTACT INFORMATION</h2>
          <p>
            If you have any queries regarding any of our terms, please contact us via the form <a
            href="/contact">here</a>.
          </p>
        </Details>
      </TermsOfServiceWrapper>
    </Wrapper>
  )
};
